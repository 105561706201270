import { css } from '@emotion/core'
import theme from 'Theme'

const { colors, zIndex } = theme

export default () => css`
  .site-wide {
    background-color: #000000;

    /* required for nav-panel-overlay positioning */
    z-index: ${zIndex.siteWide};
    position: relative;
  }
  .toggle-search {
    background-color: #000000;
  }
`
