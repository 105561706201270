const hammerson = {
  '22b3cf9f84e0': {
    children: [
      {
        name: 'Accesories',
        slug: 'accessories',
        uuid: 'e53c8e987e4e',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Women_Accessories.jpg'
      },
      {
        name: 'Shoes',
        slug: 'shoes',
        uuid: '3e3181727e08',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Women_Shoes.jpg'
      },
      {
        name: 'Dresses',
        slug: 'dresses',
        uuid: '3f580ec4e277',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Women_Dresses.jpg'
      }
    ],
    name: 'Women',
    slug: 'women'
  },
  '0b74ea8ebaf4': {
    name: 'Men',
    slug: 'men',
    children: [
      {
        name: 'Coats and Jackets',
        slug: 'coats-and-jackets',
        uuid: '3fe614f47068',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Men_Coats_Jackets.jpg'
      },
      {
        name: 'Shoes',
        slug: 'shoes',
        uuid: 'd3da8e26ff8e',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Men_Shoes.jpg'
      },
      {
        name: 'Shirts',
        slug: 'shirts',
        uuid: '08690441b7fb',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Men_Shirts.jpg'
      }
    ]
  },
  d02afe9c8aaa: {
    name: 'Beauty',
    slug: 'beauty',
    children: [
      {
        name: 'Skincare',
        slug: 'skincare',
        uuid: '4e8ecba1883b',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Beauty_Skincare.jpg'
      },
      {
        name: 'Make up',
        slug: 'makeup',
        uuid: 'ff5c6ce642f3',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Beauty_Makeup.jpg'
      },
      {
        name: 'Perfumes',
        slug: 'perfumes',
        uuid: 'f6c56f74d82c',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Beauty_Perfumes.jpg'
      }
    ]
  },
  '7c8a153fed14': {
    name: 'Homeware',
    slug: 'homeware',
    children: [
      {
        name: 'Furniture',
        slug: 'furniture',
        uuid: 'ccd6f51c2e91',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Homeware_Furniture.jpg'
      },
      {
        name: 'Lighting',
        slug: 'lighting',
        uuid: '97927c604157',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Homeware_Lighting.jpg'
      },
      {
        name: 'Decor',
        slug: 'decor',
        uuid: '3e12214c6722',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Homeware_Decor.jpg'
      }
    ]
  },
  '15e61cbb966e': {
    name: 'Kids & Baby',
    slug: 'kids-and-baby',
    children: [
      {
        name: 'Baby and Toddler',
        slug: 'baby-and-toddler',
        uuid: '8697bd751ab4',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Kids_Baby_Toddler.jpg'
      },
      {
        name: 'Boys',
        slug: 'boys',
        uuid: '3b3f59867ea2',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Kids_Boys.jpg'
      },
      {
        name: 'Girls',
        slug: 'girls',
        uuid: '37b55a640579',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Kids_Girls.jpg'
      }
    ]
  },
  '02f85df98207': {
    name: 'Travel',
    slug: 'travel',
    children: [
      {
        name: 'Backpacks',
        slug: 'backpacks',
        uuid: 'db24343b6cf3',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Travel_Backpacks.png'
      },
      {
        name: 'Duffels',
        slug: 'duffels',
        uuid: '3b98b5da4dcf',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Travel_Duffles.png'
      },
      {
        name: 'Suitcases',
        slug: 'suitcases',
        uuid: 'd3d897d455f4',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Travel_Suitcases.png'
      }
    ]
  },
  '428d48e53658': {
    name: 'Electricals',
    slug: 'electricals',
    children: [
      {
        name: 'Speakers',
        slug: 'speakers',
        uuid: 'b3b6cd96',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Electricals_Speakers.png'
      },
      {
        name: 'Phonecases',
        slug: 'phone-cases',
        uuid: 'bcf41b1af9bd',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Electricals_Phonecases.png'
      },
      {
        name: 'Headphones',
        slug: 'headphones',
        uuid: '8ca043a607a4',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Electricals_Headphones.png'
      }
    ]
  },
  a259bd33557d: {
    name: 'Entertainment',
    slug: 'entertainment',
    children: [
      {
        name: 'DVDs',
        slug: 'dvds',
        uuid: '31504edcf56d',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Entertainment_DVDs.jpg'
      },
      {
        name: 'Playstation',
        slug: 'playstation',
        uuid: '33d216a6687c',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Entertainment_Playstation.jpg'
      },
      {
        name: 'Nintendo',
        slug: 'nintendo',
        uuid: '3eb853a81495',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Entertainment_Ninendo.jpg'
      }
    ]
  },
  d7fb752e0cb0: {
    name: 'Food & Drink',
    slug: 'food-and-drink',
    children: [
      {
        name: 'Tea and Coffee',
        slug: 'tea-and-coffee',
        uuid: '1dbd9d9a',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Food_Drink_Tea_Coffee.jpg'
      },
      {
        name: 'Hampers',
        slug: 'hampers',
        uuid: '8ff60701311e',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Food_Drink_Hamper.jpg'
      },
      {
        name: 'Chocolate',
        slug: 'chocolate-and-biscuits',
        uuid: '943871d0',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Food_Drink_Chocolate.jpg'
      }
    ]
  },
  '2a0ab573bd78': {
    name: 'Stationery',
    slug: 'stationery',
    children: [
      {
        name: 'Diaries and Journals',
        slug: 'diaries-and-journals',
        uuid: 'a5033d69bfa6',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Stationery_Diaries.jpg'
      },
      {
        name: 'Notebooks',
        slug: 'notebooks',
        uuid: '5ebc3522816e',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Stationery_Notebooks.jpg'
      },
      {
        name: 'Pens and Pencils',
        slug: 'pens-and-pencils',
        uuid: '34e4d03498ad',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Stationery_Pens.jpg'
      }
    ]
  },
  '4963ad5738cf': {
    name: 'Gifts',
    slug: 'gifts',
    children: [
      {
        name: 'Photo albums',
        slug: 'photo-albums',
        uuid: '63e58f88',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Gifts_Photo_Album.jpg'
      },
      {
        name: 'Gift Wrap',
        slug: 'gift-wrap',
        uuid: '743c64b8c003',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Gifts_Wrap.jpg'
      },
      {
        name: 'Cards',
        slug: 'cards',
        uuid: '8880ee263e0b',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Gifts_Cards.jpg'
      }
    ]
  }
}
export default hammerson
