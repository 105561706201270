import { PAGE_CONTEXT as p } from 'Constants'

export default {
  useCanonical: true,
  useMainMenu: true,
  preloadCategoryImages: true,
  interStitialEnabled: true,
  trendsEnabled: false,
  articlesEnabled: false,
  useLoadMoreUI: false,
  useArticleMoreUI: false,
  heroEnabled: true,
  hero: {
    title: 'Partner name',
    intro:
      "Discover fashion trends and shop the latest products from Partner name's best brands online now"
  },
  siteWide: {
    useStickyNav: false,
    useExtraLinks: false,
    useExtraLinksDivider: true,
    searchPlaceholderText: 'Start your shopping search',
    submitButtonIcon: 'search'
  },
  contentListSkins: {
    homeTrends: 'Trend',
    trend: 'Trend',
    noSearchTrends: 'Trend',
    noSearchArticles: 'Article'
  },
  productList: {
    slots: {
      useContentListItem: true
    }
  },
  articles: {
    productMore: 'Shop more matches',
    productAlt: 'You might also like...',
    listHeading: 'Discover Style Stories',
    listSubHeading:
      'All the latest fashion features, beauty inspiration and lifestyle updates',
    homeArticles: {
      title: 'Latest style stories',
      intro:
        'Discover the latest style advice and shopping inspiration for all things fashion, beauty and lifestyle',
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 5
    },
    article: {
      title: 'Discover style stories',
      intro:
        'Read and shop the latest in all things fashion, beauty and lifestyle',
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 4
    },
    productPage: {
      title: 'Discover style stories',
      intro: 'The latest style advice and shopping inspiration',
      limit: 6
    }
  },
  articleCard: {
    withExtraInfo: true,
    withSubTitle: true,
    image: {
      small: {
        width: '340',
        height: '440'
      },
      medium: {
        width: '230',
        height: '220'
      },
      large: {
        width: '320',
        height: '320'
      }
    }
  },
  trendCard: {
    image: {
      small: {
        width: '340',
        height: '440'
      },
      medium: {
        width: '230',
        height: '220'
      },
      large: {
        width: '320',
        height: '320'
      }
    }
  },
  article: {
    useSectionLink: true,
    showTaggedCategory: true,
    item: {
      image: {
        showExtraInfo: true
      }
    }
  },
  compareCategoryLinksEnabled: true,
  showStoreNameOnProductCard: true,
  trends: {
    main: {
      title: 'Shop the latest trends',
      intro: 'Get your shopping fix with our curated product edits',
      withSub: false,
      withBtnIcon: false,
      withCta: true,
      showMore: 'View all',
      limit: 4
    },
    trend: {
      title: 'Shop the latest trends',
      intro: 'Get your shopping fix with our curated product edits',
      withIntro: true,
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 5
    },
    secondary: {
      title: 'placeholder',
      withSub: false,
      intro: 'placeholder'
    },
    listHeading: 'Style Stories',
    listSubHeading:
      'Discover the latest style advice and shopping inspiration now',
    defaultTrendCategory: 'fashion'
  },
  routes: {
    [p.OUTGOING]: {
      path: `${process.env.ANT_PREFIX}/outgoing?storeName=:storeName&to=:to`
    }
  },
  useBreadcrumbs: false,
  pagination: {
    articleList: 14,
    productList: 40,
    altProducts: 4,
    compare: 23,
    noSearchArticles: 4,
    productListArticles: 3
  },
  loading: {
    [p.COMPARE]: 8,
    [p.PRODUCT_LIST]: 8,
    [p.ARTICLE_LIST]: 8,
    [p.TREND_LIST]: 8
  },
  filters: {
    sale: {
      useCloseIcon: false,
      checkbox: false,
      label: 'Sale items'
    },
    departments: {
      enabled: true
    },
    colors: {
      enabled: true
    }
  },

  buyButton: {
    lowStockLabel: 'See at retailer',
    label: 'Buy now',
    useBuyButtonIcon: true
  },
  productPage: {
    useFeaturedArticles: true
  },
  compareTitle: 'You may also like...',
  descriptionLabel: 'Product Information',
  descriptionLabelOutOfStock: 'Out Of Stock',
  descriptionTextOutOfStockText: 'Scroll down for similar products',
  compareLinkLabel: 'View more',
  sorting: {
    lowLabel: 'Low to high',
    highLabel: 'High to low'
  },
  searchAndNotFound: {
    categoryTitle: 'Browse Top Categories',
    trendsTitle: 'Shop the latest trends',
    articlesTitle: 'Discover style stories'
  },
  siteVerifyCode: 'h6Me9AciIUHlHz4XKIBar6prh5ftkfOyvV-KAWclZRo',
  home: {
    listHeading: 'Style Stories',
    listIntro: 'Discover the latest style advice and shopping inspiration now',
    categories: {
      heading: 'Shop By Category'
    },
    storesTitle: 'Brands We Love',
    stores: [
      {
        name: 'Reiss',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/reiss.png',
        to:
          'https://www.awin1.com/cread.php?awinaffid=158752&awinmid=6985&clickref=homepagehkduym&platform=dl&p=%5B%5Bhttps%253A%252F%252Fwww.reiss.com%252F%5D%5D'
      },
      {
        name: 'Selfridges',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/selfridges.png',
        to:
          'https://www.awin1.com/cread.php?awinmid=3539&awinaffid=158752&clickref=&p=%5B%5Bhttps%253A%252F%252Fwww.selfridges.com%252FGB%252Fen%252F%5D%5D'
      },
      {
        name: 'Nike',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/nike.png',
        to:
          'https://www.awin1.com/cread.php?awinmid=16327&awinaffid=158752&clickref=homepagehkduym&p=%5B%5Bhttps%253A%252F%252Fwww.nike.com%252Fgb%252F%5D%5D'
      },
      {
        name: 'Topshop',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/topshop.png',
        to:
          'https://www.awin1.com/cread.php?awinmid=6009&awinaffid=158752&clickref=homepagehkduym&p=%5B%5Bhttps%253A%252F%252Fwww.topshop.com%252F%5D%5D'
      }
    ],
    categoryLimit: 9,
    reserveCategories: [
      {
        uuid: 'e53c8e987e4e',
        name: 'Accesories',
        slug: 'accessories',
        description:
          'Explore everything that glitters and gleams with accessories for every occasion',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/03_Women_Accessories.jpg',
        __typename: 'Category'
      },
      {
        uuid: '3e3181727e08',
        name: 'Shoes',
        slug: 'shoes',
        description:
          'Finish off your outfits in style with the latest in fashion footwear',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/02_Women_Shoes.jpg',
        __typename: 'Category'
      },
      {
        uuid: '3f580ec4e277',
        name: 'Dresses',
        slug: 'dresses',
        description: 'From mini to maxi, you’ll be twirling from day to night',
        image:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/nav-panel-categories/01_Women_Dresses.jpg',
        __typename: 'Category'
      }
    ]
  },
  blockUserAgents: [
    /Mozilla\/5\.0 \(compatible; YandexBot\/3\.0; \+http:\/\/yandex.com\/bots\)/,
    /Mozilla\/5\.0 \(Linux; Android 7\.0;\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; PetalBot;\+https:\/\/aspiegel\.com\/petalbot\)/,
    /Mozilla\/5\.0 \(Linux; Android 7\.0;\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; PetalBot;\+http:\/\/aspiegel\.com\/petalbot\)/,
    /Mozilla\/5\.0 \(compatible; Adsbot\/3\.1\)/,
    /Mozilla\/5\.0 \(compatible; Yeti\/1\.1; \+http:\/\/naver\.me\/spd\)/,
    /newspaper\/0\.2\.8/,
    /Mozilla\/5\.0 \(iPhone; CPU iPhone OS 8_1 like Mac OS X\) AppleWebKit\/600\.1\.4 \(KHTML, like Gecko\) Version\/8\.0 Mobile\/12B411 Safari\/600\.1\.4 \(compatible; YandexMobileBot\/3\.0; \+http:\/\/yandex\.com\/bots\)/,
    /Mozilla\/5\.0 \(Linux; Android 5\.0\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; Bytespider; https:\/\/zhanzhang\.toutiao\.com\/\)/
  ]
}
