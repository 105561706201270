import { PAGE_CONTEXT as p } from 'Constants'
import base from './base'
import hammerson from './hammerson'
import merge from 'lodash/merge'
import navPanelCategoryData from './navPanelCategoryData/hammersonData'

export default merge(base, hammerson, {
  name: 'sb',
  theme: 'v1',
  group: 'hammerson',
  apiKey: 'tUaSn8MApLMQB6NAYtnfdg7hadJjp5x5dSbgvM2yKPzrTN5M',
  siteVerifyCode: 'wD3U4wdDOtqLHflb7N5AUxp0DtBiZYVXyGyWH_e8Qgw',
  articlesOnProductListPage: true,
  categoryTitle: 'Shop by category',
  pagination: {
    articleList: 12,
    productList: 40,
    altProducts: 4,
    compare: 23,
    noSearchArticles: 4,
    productListArticles: 3
  },
  hero: {
    title: 'The Stylo',
    intro:
      "Discover fashion trends and shop the latest products from The Stylo's best brands online now."
  },
  helmet: {
    metaTitleFormat: {
      [p.TREND_LIST]:
        'Get your fashion fix fast with easy-to-shop trends edits. | {{PARTNER_NAME}}',
      [p.ARTICLE_LIST]:
        'All the latest fashion features, beauty inspiration and lifestyle updates. | {{PARTNER_NAME}}'
    },
    partner: 'The Stylo',
    articles: {
      title: 'Latest shopping articles - The Stylo',
      description: `View all the latest Shopping Articles from The Stylo`
    },
    defaultDescription: 'The Stylo'
  },
  routes: {
    [p.HUB]: {
      page: 'HubPage',
      path: `${process.env.ANT_PREFIX}/hub/:uuid/:slug`
    }
  },
  home: {
    articlesLinkEnabled: false,
    stores: [
      {
        name: 'Kurt G',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=24803&u1=homepageklmnnh&murl=https%3A%2F%2Fwww.kurtgeiger.com%2F',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/k_geiger.png'
      },
      {
        name: 'Phase Eight',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=44103&u1=homepageklmnnh&murl=https%3A%2F%2Fwww.phase-eight.com%2F',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/phase_eight.png'
      },
      {
        name: 'Topshop',
        to:
          'https://www.awin1.com/cread.php?awinmid=6009&awinaffid=158752&clickref=homepageklmnnh&p=%5B%5Bhttps%253A%252F%252Fwww.topshop.com%252F%5D%5D',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/topshop.png'
      },
      {
        name: 'River island',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=44103&u1=homepageklmnnh&murl=https%3A%2F%2Fwww.phase-eight.com%2F',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/river_island.png'
      }
    ]
  },
  navPanelCategoryData,
  storesBlacklist: [
    1006,
    1016,
    1023,
    1029,
    1037,
    511,
    512,
    520,
    521,
    550,
    554,
    609,
    629,
    653,
    684,
    726,
    745,
    752,
    779,
    784,
    791,
    795,
    804,
    805,
    808,
    844,
    848,
    866,
    879,
    882,
    904,
    921,
    925,
    944,
    950,
    958,
    959,
    970,
    976,
    998
  ]
})
